// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareVehicleMediaFields() {
    val props: Array<SerializableProperty<VehicleMedia, *>> = arrayOf(VehicleMedia_file, VehicleMedia_type)
    VehicleMedia.serializer().properties { props }
}
val <K> DataClassPath<K, VehicleMedia>.file: DataClassPath<K, ServerFile> get() = this[VehicleMedia_file]
val <K> DataClassPath<K, VehicleMedia>.type: DataClassPath<K, String> get() = this[VehicleMedia_type]
inline val VehicleMedia.Companion.path: DataClassPath<VehicleMedia, VehicleMedia> get() = path<VehicleMedia>()


object VehicleMedia_file: SerializableProperty<VehicleMedia, ServerFile> {
    override val name: String = "file"
    override fun get(receiver: VehicleMedia): ServerFile = receiver.file
    override fun setCopy(receiver: VehicleMedia, value: ServerFile) = receiver.copy(file = value)
    override val serializer: KSerializer<ServerFile> = ContextualSerializer(ServerFile::class)
    override val annotations: List<Annotation> = VehicleMedia.serializer().tryFindAnnotations("file")
}
object VehicleMedia_type: SerializableProperty<VehicleMedia, String> {
    override val name: String = "type"
    override fun get(receiver: VehicleMedia): String = receiver.type
    override fun setCopy(receiver: VehicleMedia, value: String) = receiver.copy(type = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = VehicleMedia.serializer().tryFindAnnotations("type")
}
