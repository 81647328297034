// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareVehicleFields() {
    val props: Array<SerializableProperty<Vehicle, *>> = arrayOf(Vehicle__id, Vehicle_orderingValue, Vehicle_geoCoordinate, Vehicle_createdAt, Vehicle_submitted, Vehicle_liveAt, Vehicle_archived, Vehicle_completion, Vehicle_paid, Vehicle_received, Vehicle_cancelled, Vehicle_cancellationReason, Vehicle_rerunStarted, Vehicle_auctioneer, Vehicle_estimatedValue, Vehicle_reserve, Vehicle_internalCost, Vehicle_autobids, Vehicle_vin, Vehicle_year, Vehicle_yearString, Vehicle_make, Vehicle_model, Vehicle_trim, Vehicle_options, Vehicle_odometer, Vehicle_transmission, Vehicle_fuelType, Vehicle_interiorColor, Vehicle_exteriorColor, Vehicle_photos, Vehicle_media, Vehicle_thumbnail, Vehicle_damage, Vehicle_keys, Vehicle_tires, Vehicle_activeWarranty, Vehicle_description, Vehicle_considerationsFilled, Vehicle_priorAccident, Vehicle_paintwork, Vehicle_warningLights, Vehicle_towRequired, Vehicle_nonRunner, Vehicle_structuralDamage, Vehicle_airConditioningIssue, Vehicle_transmissionIssue, Vehicle_odometerIssue, Vehicle_canadian, Vehicle_salvage, Vehicle_lemonLaw, Vehicle_flood, Vehicle_stolenOrRecovery, Vehicle_rentalOrTaxi, Vehicle_trueMileageUnknown, Vehicle_location, Vehicle_attachments, Vehicle_nearNotificationSent, Vehicle_auctionLane)
    Vehicle.serializer().properties { props }
}
val <K> DataClassPath<K, Vehicle>._id: DataClassPath<K, UUID> get() = this[Vehicle__id]
val <K> DataClassPath<K, Vehicle>.orderingValue: DataClassPath<K, Float> get() = this[Vehicle_orderingValue]
val <K> DataClassPath<K, Vehicle>.geoCoordinate: DataClassPath<K, GeoCoordinate> get() = this[Vehicle_geoCoordinate]
val <K> DataClassPath<K, Vehicle>.createdAt: DataClassPath<K, Instant> get() = this[Vehicle_createdAt]
val <K> DataClassPath<K, Vehicle>.submitted: DataClassPath<K, Instant?> get() = this[Vehicle_submitted]
val <K> DataClassPath<K, Vehicle>.liveAt: DataClassPath<K, Instant?> get() = this[Vehicle_liveAt]
val <K> DataClassPath<K, Vehicle>.archived: DataClassPath<K, Instant?> get() = this[Vehicle_archived]
val <K> DataClassPath<K, Vehicle>.completion: DataClassPath<K, Completion?> get() = this[Vehicle_completion]
val <K> DataClassPath<K, Vehicle>.paid: DataClassPath<K, Instant?> get() = this[Vehicle_paid]
val <K> DataClassPath<K, Vehicle>.received: DataClassPath<K, Instant?> get() = this[Vehicle_received]
val <K> DataClassPath<K, Vehicle>.cancelled: DataClassPath<K, Instant?> get() = this[Vehicle_cancelled]
val <K> DataClassPath<K, Vehicle>.cancellationReason: DataClassPath<K, CancellationReason?> get() = this[Vehicle_cancellationReason]
val <K> DataClassPath<K, Vehicle>.rerunStarted: DataClassPath<K, Instant?> get() = this[Vehicle_rerunStarted]
val <K> DataClassPath<K, Vehicle>.auctioneer: DataClassPath<K, Auctioneer?> get() = this[Vehicle_auctioneer]
val <K> DataClassPath<K, Vehicle>.estimatedValue: DataClassPath<K, PriceInDollars> get() = this[Vehicle_estimatedValue]
val <K> DataClassPath<K, Vehicle>.reserve: DataClassPath<K, PriceInDollars?> get() = this[Vehicle_reserve]
val <K> DataClassPath<K, Vehicle>.internalCost: DataClassPath<K, PriceInDollars?> get() = this[Vehicle_internalCost]
val <K> DataClassPath<K, Vehicle>.autobids: DataClassPath<K, Int> get() = this[Vehicle_autobids]
val <K> DataClassPath<K, Vehicle>.vin: DataClassPath<K, String> get() = this[Vehicle_vin]
val <K> DataClassPath<K, Vehicle>.year: DataClassPath<K, Short?> get() = this[Vehicle_year]
val <K> DataClassPath<K, Vehicle>.yearString: DataClassPath<K, String?> get() = this[Vehicle_yearString]
val <K> DataClassPath<K, Vehicle>.make: DataClassPath<K, String?> get() = this[Vehicle_make]
val <K> DataClassPath<K, Vehicle>.model: DataClassPath<K, String?> get() = this[Vehicle_model]
val <K> DataClassPath<K, Vehicle>.trim: DataClassPath<K, String?> get() = this[Vehicle_trim]
val <K> DataClassPath<K, Vehicle>.options: DataClassPath<K, String?> get() = this[Vehicle_options]
val <K> DataClassPath<K, Vehicle>.odometer: DataClassPath<K, Int?> get() = this[Vehicle_odometer]
val <K> DataClassPath<K, Vehicle>.transmission: DataClassPath<K, Transmission?> get() = this[Vehicle_transmission]
val <K> DataClassPath<K, Vehicle>.fuelType: DataClassPath<K, FuelType?> get() = this[Vehicle_fuelType]
val <K> DataClassPath<K, Vehicle>.interiorColor: DataClassPath<K, VehicleColor?> get() = this[Vehicle_interiorColor]
val <K> DataClassPath<K, Vehicle>.exteriorColor: DataClassPath<K, VehicleColor?> get() = this[Vehicle_exteriorColor]
val <K> DataClassPath<K, Vehicle>.photos: DataClassPath<K, List<VehiclePhoto>> get() = this[Vehicle_photos]
val <K> DataClassPath<K, Vehicle>.media: DataClassPath<K, List<VehicleMedia>> get() = this[Vehicle_media]
val <K> DataClassPath<K, Vehicle>.thumbnail: DataClassPath<K, ServerFile?> get() = this[Vehicle_thumbnail]
val <K> DataClassPath<K, Vehicle>.damage: DataClassPath<K, List<Damage>?> get() = this[Vehicle_damage]
val <K> DataClassPath<K, Vehicle>.keys: DataClassPath<K, KeyCount?> get() = this[Vehicle_keys]
val <K> DataClassPath<K, Vehicle>.tires: DataClassPath<K, TireStatus?> get() = this[Vehicle_tires]
val <K> DataClassPath<K, Vehicle>.activeWarranty: DataClassPath<K, Boolean?> get() = this[Vehicle_activeWarranty]
val <K> DataClassPath<K, Vehicle>.description: DataClassPath<K, String?> get() = this[Vehicle_description]
val <K> DataClassPath<K, Vehicle>.considerationsFilled: DataClassPath<K, Boolean> get() = this[Vehicle_considerationsFilled]
val <K> DataClassPath<K, Vehicle>.priorAccident: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_priorAccident]
val <K> DataClassPath<K, Vehicle>.paintwork: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_paintwork]
val <K> DataClassPath<K, Vehicle>.warningLights: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_warningLights]
val <K> DataClassPath<K, Vehicle>.towRequired: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_towRequired]
val <K> DataClassPath<K, Vehicle>.nonRunner: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_nonRunner]
val <K> DataClassPath<K, Vehicle>.structuralDamage: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_structuralDamage]
val <K> DataClassPath<K, Vehicle>.airConditioningIssue: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_airConditioningIssue]
val <K> DataClassPath<K, Vehicle>.transmissionIssue: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_transmissionIssue]
val <K> DataClassPath<K, Vehicle>.odometerIssue: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_odometerIssue]
val <K> DataClassPath<K, Vehicle>.canadian: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_canadian]
val <K> DataClassPath<K, Vehicle>.salvage: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_salvage]
val <K> DataClassPath<K, Vehicle>.lemonLaw: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_lemonLaw]
val <K> DataClassPath<K, Vehicle>.flood: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_flood]
val <K> DataClassPath<K, Vehicle>.stolenOrRecovery: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_stolenOrRecovery]
val <K> DataClassPath<K, Vehicle>.rentalOrTaxi: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_rentalOrTaxi]
val <K> DataClassPath<K, Vehicle>.trueMileageUnknown: DataClassPath<K, ExtraInfo?> get() = this[Vehicle_trueMileageUnknown]
val <K> DataClassPath<K, Vehicle>.location: DataClassPath<K, UsAddress> get() = this[Vehicle_location]
val <K> DataClassPath<K, Vehicle>.attachments: DataClassPath<K, List<Attachment>> get() = this[Vehicle_attachments]
val <K> DataClassPath<K, Vehicle>.nearNotificationSent: DataClassPath<K, Instant?> get() = this[Vehicle_nearNotificationSent]
val <K> DataClassPath<K, Vehicle>.auctionLane: DataClassPath<K, UUID?> get() = this[Vehicle_auctionLane]
inline val Vehicle.Companion.path: DataClassPath<Vehicle, Vehicle> get() = path<Vehicle>()


object Vehicle__id: SerializableProperty<Vehicle, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Vehicle): UUID = receiver._id
    override fun setCopy(receiver: Vehicle, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object Vehicle_orderingValue: SerializableProperty<Vehicle, Float> {
    override val name: String = "orderingValue"
    override fun get(receiver: Vehicle): Float = receiver.orderingValue
    override fun setCopy(receiver: Vehicle, value: Float) = receiver.copy(orderingValue = value)
    override val serializer: KSerializer<Float> = Float.serializer()
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("orderingValue")
    override val default: Float = 0f
}
object Vehicle_geoCoordinate: SerializableProperty<Vehicle, GeoCoordinate> {
    override val name: String = "geoCoordinate"
    override fun get(receiver: Vehicle): GeoCoordinate = receiver.geoCoordinate
    override fun setCopy(receiver: Vehicle, value: GeoCoordinate) = receiver.copy(geoCoordinate = value)
    override val serializer: KSerializer<GeoCoordinate> = GeoCoordinate.serializer()
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("geoCoordinate")
    override val default: GeoCoordinate = GeoCoordinate(0.0, 0.0)
}
object Vehicle_createdAt: SerializableProperty<Vehicle, Instant> {
    override val name: String = "createdAt"
    override fun get(receiver: Vehicle): Instant = receiver.createdAt
    override fun setCopy(receiver: Vehicle, value: Instant) = receiver.copy(createdAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("createdAt")
    override val default: Instant = now()
}
object Vehicle_submitted: SerializableProperty<Vehicle, Instant?> {
    override val name: String = "submitted"
    override fun get(receiver: Vehicle): Instant? = receiver.submitted
    override fun setCopy(receiver: Vehicle, value: Instant?) = receiver.copy(submitted = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("submitted")
    override val default: Instant? = null
}
object Vehicle_liveAt: SerializableProperty<Vehicle, Instant?> {
    override val name: String = "liveAt"
    override fun get(receiver: Vehicle): Instant? = receiver.liveAt
    override fun setCopy(receiver: Vehicle, value: Instant?) = receiver.copy(liveAt = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("liveAt")
    override val default: Instant? = null
}
object Vehicle_archived: SerializableProperty<Vehicle, Instant?> {
    override val name: String = "archived"
    override fun get(receiver: Vehicle): Instant? = receiver.archived
    override fun setCopy(receiver: Vehicle, value: Instant?) = receiver.copy(archived = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("archived")
    override val default: Instant? = null
}
object Vehicle_completion: SerializableProperty<Vehicle, Completion?> {
    override val name: String = "completion"
    override fun get(receiver: Vehicle): Completion? = receiver.completion
    override fun setCopy(receiver: Vehicle, value: Completion?) = receiver.copy(completion = value)
    override val serializer: KSerializer<Completion?> = Completion.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("completion")
    override val default: Completion? = null
}
object Vehicle_paid: SerializableProperty<Vehicle, Instant?> {
    override val name: String = "paid"
    override fun get(receiver: Vehicle): Instant? = receiver.paid
    override fun setCopy(receiver: Vehicle, value: Instant?) = receiver.copy(paid = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("paid")
    override val default: Instant? = null
}
object Vehicle_received: SerializableProperty<Vehicle, Instant?> {
    override val name: String = "received"
    override fun get(receiver: Vehicle): Instant? = receiver.received
    override fun setCopy(receiver: Vehicle, value: Instant?) = receiver.copy(received = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("received")
    override val default: Instant? = null
}
object Vehicle_cancelled: SerializableProperty<Vehicle, Instant?> {
    override val name: String = "cancelled"
    override fun get(receiver: Vehicle): Instant? = receiver.cancelled
    override fun setCopy(receiver: Vehicle, value: Instant?) = receiver.copy(cancelled = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("cancelled")
    override val default: Instant? = null
}
object Vehicle_cancellationReason: SerializableProperty<Vehicle, CancellationReason?> {
    override val name: String = "cancellationReason"
    override fun get(receiver: Vehicle): CancellationReason? = receiver.cancellationReason
    override fun setCopy(receiver: Vehicle, value: CancellationReason?) = receiver.copy(cancellationReason = value)
    override val serializer: KSerializer<CancellationReason?> = CancellationReason.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("cancellationReason")
    override val default: CancellationReason? = null
}
object Vehicle_rerunStarted: SerializableProperty<Vehicle, Instant?> {
    override val name: String = "rerunStarted"
    override fun get(receiver: Vehicle): Instant? = receiver.rerunStarted
    override fun setCopy(receiver: Vehicle, value: Instant?) = receiver.copy(rerunStarted = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("rerunStarted")
    override val default: Instant? = null
}
object Vehicle_auctioneer: SerializableProperty<Vehicle, Auctioneer?> {
    override val name: String = "auctioneer"
    override fun get(receiver: Vehicle): Auctioneer? = receiver.auctioneer
    override fun setCopy(receiver: Vehicle, value: Auctioneer?) = receiver.copy(auctioneer = value)
    override val serializer: KSerializer<Auctioneer?> = Auctioneer.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("auctioneer")
    override val default: Auctioneer? = null
}
object Vehicle_estimatedValue: SerializableProperty<Vehicle, PriceInDollars> {
    override val name: String = "estimatedValue"
    override fun get(receiver: Vehicle): PriceInDollars = receiver.estimatedValue
    override fun setCopy(receiver: Vehicle, value: PriceInDollars) = receiver.copy(estimatedValue = value)
    override val serializer: KSerializer<PriceInDollars> = PriceInDollars.serializer()
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("estimatedValue")
    override val default: PriceInDollars = 0
}
object Vehicle_reserve: SerializableProperty<Vehicle, PriceInDollars?> {
    override val name: String = "reserve"
    override fun get(receiver: Vehicle): PriceInDollars? = receiver.reserve
    override fun setCopy(receiver: Vehicle, value: PriceInDollars?) = receiver.copy(reserve = value)
    override val serializer: KSerializer<PriceInDollars?> = PriceInDollars.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("reserve")
    override val default: PriceInDollars? = null
}
object Vehicle_internalCost: SerializableProperty<Vehicle, PriceInDollars?> {
    override val name: String = "internalCost"
    override fun get(receiver: Vehicle): PriceInDollars? = receiver.internalCost
    override fun setCopy(receiver: Vehicle, value: PriceInDollars?) = receiver.copy(internalCost = value)
    override val serializer: KSerializer<PriceInDollars?> = PriceInDollars.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("internalCost")
    override val default: PriceInDollars? = null
}
object Vehicle_autobids: SerializableProperty<Vehicle, Int> {
    override val name: String = "autobids"
    override fun get(receiver: Vehicle): Int = receiver.autobids
    override fun setCopy(receiver: Vehicle, value: Int) = receiver.copy(autobids = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("autobids")
    override val default: Int = 0
}
object Vehicle_vin: SerializableProperty<Vehicle, String> {
    override val name: String = "vin"
    override fun get(receiver: Vehicle): String = receiver.vin
    override fun setCopy(receiver: Vehicle, value: String) = receiver.copy(vin = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("vin")
}
object Vehicle_year: SerializableProperty<Vehicle, Short?> {
    override val name: String = "year"
    override fun get(receiver: Vehicle): Short? = receiver.year
    override fun setCopy(receiver: Vehicle, value: Short?) = receiver.copy(year = value)
    override val serializer: KSerializer<Short?> = Short.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("year")
    override val default: Short? = null
}
object Vehicle_yearString: SerializableProperty<Vehicle, String?> {
    override val name: String = "yearString"
    override fun get(receiver: Vehicle): String? = receiver.yearString
    override fun setCopy(receiver: Vehicle, value: String?) = receiver.copy(yearString = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("yearString")
}
object Vehicle_make: SerializableProperty<Vehicle, String?> {
    override val name: String = "make"
    override fun get(receiver: Vehicle): String? = receiver.make
    override fun setCopy(receiver: Vehicle, value: String?) = receiver.copy(make = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("make")
    override val default: String? = null
}
object Vehicle_model: SerializableProperty<Vehicle, String?> {
    override val name: String = "model"
    override fun get(receiver: Vehicle): String? = receiver.model
    override fun setCopy(receiver: Vehicle, value: String?) = receiver.copy(model = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("model")
    override val default: String? = null
}
object Vehicle_trim: SerializableProperty<Vehicle, String?> {
    override val name: String = "trim"
    override fun get(receiver: Vehicle): String? = receiver.trim
    override fun setCopy(receiver: Vehicle, value: String?) = receiver.copy(trim = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("trim")
    override val default: String? = null
}
object Vehicle_options: SerializableProperty<Vehicle, String?> {
    override val name: String = "options"
    override fun get(receiver: Vehicle): String? = receiver.options
    override fun setCopy(receiver: Vehicle, value: String?) = receiver.copy(options = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("options")
    override val default: String? = null
}
object Vehicle_odometer: SerializableProperty<Vehicle, Int?> {
    override val name: String = "odometer"
    override fun get(receiver: Vehicle): Int? = receiver.odometer
    override fun setCopy(receiver: Vehicle, value: Int?) = receiver.copy(odometer = value)
    override val serializer: KSerializer<Int?> = Int.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("odometer")
    override val default: Int? = null
}
object Vehicle_transmission: SerializableProperty<Vehicle, Transmission?> {
    override val name: String = "transmission"
    override fun get(receiver: Vehicle): Transmission? = receiver.transmission
    override fun setCopy(receiver: Vehicle, value: Transmission?) = receiver.copy(transmission = value)
    override val serializer: KSerializer<Transmission?> = Transmission.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("transmission")
    override val default: Transmission? = null
}
object Vehicle_fuelType: SerializableProperty<Vehicle, FuelType?> {
    override val name: String = "fuelType"
    override fun get(receiver: Vehicle): FuelType? = receiver.fuelType
    override fun setCopy(receiver: Vehicle, value: FuelType?) = receiver.copy(fuelType = value)
    override val serializer: KSerializer<FuelType?> = FuelType.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("fuelType")
    override val default: FuelType? = null
}
object Vehicle_interiorColor: SerializableProperty<Vehicle, VehicleColor?> {
    override val name: String = "interiorColor"
    override fun get(receiver: Vehicle): VehicleColor? = receiver.interiorColor
    override fun setCopy(receiver: Vehicle, value: VehicleColor?) = receiver.copy(interiorColor = value)
    override val serializer: KSerializer<VehicleColor?> = VehicleColor.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("interiorColor")
    override val default: VehicleColor? = null
}
object Vehicle_exteriorColor: SerializableProperty<Vehicle, VehicleColor?> {
    override val name: String = "exteriorColor"
    override fun get(receiver: Vehicle): VehicleColor? = receiver.exteriorColor
    override fun setCopy(receiver: Vehicle, value: VehicleColor?) = receiver.copy(exteriorColor = value)
    override val serializer: KSerializer<VehicleColor?> = VehicleColor.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("exteriorColor")
    override val default: VehicleColor? = null
}
object Vehicle_photos: SerializableProperty<Vehicle, List<VehiclePhoto>> {
    override val name: String = "photos"
    override fun get(receiver: Vehicle): List<VehiclePhoto> = receiver.photos
    override fun setCopy(receiver: Vehicle, value: List<VehiclePhoto>) = receiver.copy(photos = value)
    override val serializer: KSerializer<List<VehiclePhoto>> = ListSerializer(VehiclePhoto.serializer())
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("photos")
    override val default: List<VehiclePhoto> = listOf()
}
object Vehicle_media: SerializableProperty<Vehicle, List<VehicleMedia>> {
    override val name: String = "media"
    override fun get(receiver: Vehicle): List<VehicleMedia> = receiver.media
    override fun setCopy(receiver: Vehicle, value: List<VehicleMedia>) = receiver.copy(media = value)
    override val serializer: KSerializer<List<VehicleMedia>> = ListSerializer(VehicleMedia.serializer())
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("media")
    override val default: List<VehicleMedia> = listOf()
}
object Vehicle_thumbnail: SerializableProperty<Vehicle, ServerFile?> {
    override val name: String = "thumbnail"
    override fun get(receiver: Vehicle): ServerFile? = receiver.thumbnail
    override fun setCopy(receiver: Vehicle, value: ServerFile?) = receiver.copy(thumbnail = value)
    override val serializer: KSerializer<ServerFile?> = ContextualSerializer(ServerFile::class).nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("thumbnail")
    override val default: ServerFile? = null
}
object Vehicle_damage: SerializableProperty<Vehicle, List<Damage>?> {
    override val name: String = "damage"
    override fun get(receiver: Vehicle): List<Damage>? = receiver.damage
    override fun setCopy(receiver: Vehicle, value: List<Damage>?) = receiver.copy(damage = value)
    override val serializer: KSerializer<List<Damage>?> = ListSerializer(Damage.serializer()).nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("damage")
    override val default: List<Damage>? = null
}
object Vehicle_keys: SerializableProperty<Vehicle, KeyCount?> {
    override val name: String = "keys"
    override fun get(receiver: Vehicle): KeyCount? = receiver.keys
    override fun setCopy(receiver: Vehicle, value: KeyCount?) = receiver.copy(keys = value)
    override val serializer: KSerializer<KeyCount?> = KeyCount.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("keys")
    override val default: KeyCount? = null
}
object Vehicle_tires: SerializableProperty<Vehicle, TireStatus?> {
    override val name: String = "tires"
    override fun get(receiver: Vehicle): TireStatus? = receiver.tires
    override fun setCopy(receiver: Vehicle, value: TireStatus?) = receiver.copy(tires = value)
    override val serializer: KSerializer<TireStatus?> = TireStatus.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("tires")
    override val default: TireStatus? = null
}
object Vehicle_activeWarranty: SerializableProperty<Vehicle, Boolean?> {
    override val name: String = "activeWarranty"
    override fun get(receiver: Vehicle): Boolean? = receiver.activeWarranty
    override fun setCopy(receiver: Vehicle, value: Boolean?) = receiver.copy(activeWarranty = value)
    override val serializer: KSerializer<Boolean?> = Boolean.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("activeWarranty")
    override val default: Boolean? = null
}
object Vehicle_description: SerializableProperty<Vehicle, String?> {
    override val name: String = "description"
    override fun get(receiver: Vehicle): String? = receiver.description
    override fun setCopy(receiver: Vehicle, value: String?) = receiver.copy(description = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("description")
    override val default: String? = null
}
object Vehicle_considerationsFilled: SerializableProperty<Vehicle, Boolean> {
    override val name: String = "considerationsFilled"
    override fun get(receiver: Vehicle): Boolean = receiver.considerationsFilled
    override fun setCopy(receiver: Vehicle, value: Boolean) = receiver.copy(considerationsFilled = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("considerationsFilled")
    override val default: Boolean = false
}
object Vehicle_priorAccident: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "priorAccident"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.priorAccident
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(priorAccident = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("priorAccident")
    override val default: ExtraInfo? = null
}
object Vehicle_paintwork: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "paintwork"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.paintwork
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(paintwork = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("paintwork")
    override val default: ExtraInfo? = null
}
object Vehicle_warningLights: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "warningLights"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.warningLights
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(warningLights = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("warningLights")
    override val default: ExtraInfo? = null
}
object Vehicle_towRequired: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "towRequired"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.towRequired
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(towRequired = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("towRequired")
    override val default: ExtraInfo? = null
}
object Vehicle_nonRunner: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "nonRunner"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.nonRunner
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(nonRunner = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("nonRunner")
    override val default: ExtraInfo? = null
}
object Vehicle_structuralDamage: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "structuralDamage"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.structuralDamage
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(structuralDamage = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("structuralDamage")
    override val default: ExtraInfo? = null
}
object Vehicle_airConditioningIssue: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "airConditioningIssue"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.airConditioningIssue
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(airConditioningIssue = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("airConditioningIssue")
    override val default: ExtraInfo? = null
}
object Vehicle_transmissionIssue: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "transmissionIssue"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.transmissionIssue
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(transmissionIssue = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("transmissionIssue")
    override val default: ExtraInfo? = null
}
object Vehicle_odometerIssue: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "odometerIssue"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.odometerIssue
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(odometerIssue = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("odometerIssue")
    override val default: ExtraInfo? = null
}
object Vehicle_canadian: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "canadian"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.canadian
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(canadian = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("canadian")
    override val default: ExtraInfo? = null
}
object Vehicle_salvage: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "salvage"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.salvage
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(salvage = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("salvage")
    override val default: ExtraInfo? = null
}
object Vehicle_lemonLaw: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "lemonLaw"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.lemonLaw
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(lemonLaw = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("lemonLaw")
    override val default: ExtraInfo? = null
}
object Vehicle_flood: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "flood"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.flood
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(flood = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("flood")
    override val default: ExtraInfo? = null
}
object Vehicle_stolenOrRecovery: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "stolenOrRecovery"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.stolenOrRecovery
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(stolenOrRecovery = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("stolenOrRecovery")
    override val default: ExtraInfo? = null
}
object Vehicle_rentalOrTaxi: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "rentalOrTaxi"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.rentalOrTaxi
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(rentalOrTaxi = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("rentalOrTaxi")
    override val default: ExtraInfo? = null
}
object Vehicle_trueMileageUnknown: SerializableProperty<Vehicle, ExtraInfo?> {
    override val name: String = "trueMileageUnknown"
    override fun get(receiver: Vehicle): ExtraInfo? = receiver.trueMileageUnknown
    override fun setCopy(receiver: Vehicle, value: ExtraInfo?) = receiver.copy(trueMileageUnknown = value)
    override val serializer: KSerializer<ExtraInfo?> = ExtraInfo.serializer().nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("trueMileageUnknown")
    override val default: ExtraInfo? = null
}
object Vehicle_location: SerializableProperty<Vehicle, UsAddress> {
    override val name: String = "location"
    override fun get(receiver: Vehicle): UsAddress = receiver.location
    override fun setCopy(receiver: Vehicle, value: UsAddress) = receiver.copy(location = value)
    override val serializer: KSerializer<UsAddress> = UsAddress.serializer()
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("location")
    override val default: UsAddress = UsAddress()
}
object Vehicle_attachments: SerializableProperty<Vehicle, List<Attachment>> {
    override val name: String = "attachments"
    override fun get(receiver: Vehicle): List<Attachment> = receiver.attachments
    override fun setCopy(receiver: Vehicle, value: List<Attachment>) = receiver.copy(attachments = value)
    override val serializer: KSerializer<List<Attachment>> = ListSerializer(Attachment.serializer())
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("attachments")
    override val default: List<Attachment> = listOf()
}
object Vehicle_nearNotificationSent: SerializableProperty<Vehicle, Instant?> {
    override val name: String = "nearNotificationSent"
    override fun get(receiver: Vehicle): Instant? = receiver.nearNotificationSent
    override fun setCopy(receiver: Vehicle, value: Instant?) = receiver.copy(nearNotificationSent = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("nearNotificationSent")
    override val default: Instant? = null
}
object Vehicle_auctionLane: SerializableProperty<Vehicle, UUID?> {
    override val name: String = "auctionLane"
    override fun get(receiver: Vehicle): UUID? = receiver.auctionLane
    override fun setCopy(receiver: Vehicle, value: UUID?) = receiver.copy(auctionLane = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = Vehicle.serializer().tryFindAnnotations("auctionLane")
    override val default: UUID? = null
}
