package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.*
import com.ilussobsa.utils.*
import com.ilussobsa.views.dialogs.GenericConfirmationDialog
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.exceptions.PlainTextException
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.DefaultJson
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.field
import com.lightningkite.lightningdb.modification
import com.lightningkite.lightningserver.auth.proof.FinishProof
import com.lightningkite.lightningserver.auth.proof.IdentificationAndPassword
import com.lightningkite.lightningserver.auth.proof.Proof
import com.lightningkite.serialization.lensPath
import kotlinx.coroutines.*
import kotlinx.serialization.Serializable

interface ControlsNavVisibility {
    fun ReactiveContext.showNav(): Boolean
}

@Routable("login")
class LogInScreen() : KiteUiScreen, ControlsNavVisibility {

    override fun ReactiveContext.showNav(): Boolean = false

    @QueryParameter
    val proof = Property<String?>(null)

    @QueryParameter
    val backend = Property<String?>(null)

    @QueryParameter
    val notice = Property<Notice?>(null)

    @Serializable
    enum class Notice(val message: String) {
        UserAlreadyExists("User already exists"),
        UserDoesNotExist("User doesn't exist")
    }

    enum class LoginScreenStage { EnterEmail, EnterOTP, CreateAccount }

    val email = Property("")
    val name = Property("")
    val phoneNumber = Property("")
    val address = Property(UsAddress())
    val passcodeSecret = Property<String?>(null)
    val passcode = Property("")
    // true for login view, false for create account view
    val loginView = Property(true)

    private val usePassword = "USE PASSWORD"

    override fun ViewWriter.render() {
        println("LoginScreen rendered")
        launchGlobal {
            println("Login screen would wipe session")
            // TODO: WTF
            sessionToken.value = null
        }

        fun ViewWriter.noticeBox() = card - row {
            ::exists { notice() != null }
            icon { source = Icon.checkCircle }
            centered - text {
                ::content { notice()?.message ?: "" }
            }
        }

        fun ViewWriter.mainCol() = overimage - stack {
            spacing = 3.rem
            atTopCenter - noticeBox()
            gravity(Align.Center, Align.Stretch) - sizeConstraints(width = 40.rem) - col {
                expanding - space()
                centered - image {
                    source = Resources.ilussoFullLogo(defaultAppTheme)
                    description = Strings.appName
                }
                separator()
                val userFullyCreated = shared {
                    currentSessionNullable()?.me?.invoke()?.let {
                        (it.role == UserRole.UnverifiedCustomer &&
                                it.name.isNotEmpty() && it.phoneNumber?.isEmpty() == false &&
                                it.address.zip?.isEmpty() == false &&
                                it.address.city?.isEmpty() == false &&
                                it.address.state != null
                                )
                                || it.role != UserRole.UnverifiedCustomer
                    }
                }

                fun ReactiveContext.stage(): LoginScreenStage =
                    if (userFullyCreated() == false) LoginScreenStage.CreateAccount
                    else if (passcodeSecret() != null) LoginScreenStage.EnterOTP
                    else LoginScreenStage.EnterEmail

                onlyWhen { stage() == LoginScreenStage.EnterEmail } - col {
                    val field: TextField
                    label {
                        content = Strings.email2
                        fieldTheme - textField {
                            hint = "someone@dealership.com"
                            keyboardHints = KeyboardHints.email
                            content bind email
                            field = this
                        }
                    }
                    important - button {
                        ::enabled { email().matches(emailPattern) }
                        text {
                            ::exists { loginView() }
                            align = Align.Center
                            content = Strings.sendALoginCode
                        }
                        text {
                            ::exists { !loginView() }
                            align = Align.Center
                            content = Strings.createAccount
                        }
                        onClickAssociatedField(field, action = {
                            val notice = sendEmail(loginView())
                            if (notice == Notice.UserDoesNotExist) {
                                dialogScreenNavigator.navigate(
                                    GenericConfirmationDialog(
                                        message = "This user does not exist, do you want to create a user with this email?",
                                        confirmMessage = "Create a new user",
                                        onConfirm = {
                                            if (it) {
                                                sendEmail(!loginView())
                                            }
                                        }
                                    )
                                )
                            } else if (notice == Notice.UserAlreadyExists) {
                                dialogScreenNavigator.navigate(
                                    GenericConfirmationDialog(
                                        message = "A user already exists with this email, do you want to login with this email?",
                                        confirmMessage = "Continue to login",
                                        onConfirm = {
                                            if (it) {
                                                sendEmail(!loginView())
                                            }
                                        }
                                    )
                                )
                            }
                        })
                    }
//            oauthButtons()
                }
                onlyWhen { stage() == LoginScreenStage.EnterOTP } - col {

                    suspend fun confirmPasscode() {
                        try {
                            val api = selectedApi.await().api
                            val secret = passcodeSecret.await()
                            val proof =
                                if (secret == usePassword)
                                    api.passwordProof.provePasswordOwnership(
                                        IdentificationAndPassword(
                                            type = "User",
                                            property = "email",
                                            value = email(),
                                            password = passcode(),
                                        )
                                    )
                                else
                                    api.emailProof.proveEmailOwnership(
                                        FinishProof(
                                            passcodeSecret.await() ?: throw Exception("This shouldn't be null. B"),
                                            passcode.await()
                                        )
                                    )
                            val session = api.userAuth.logIn(listOf(proof))
                            sessionToken.set(session.session ?: throw Exception("This shouldn't be null. C"))
                            println("Session token is set")
//                delay(1000)
                            if (userFullyCreated.awaitNotNull()) screenNavigator.reset(DashboardScreen())
                            email set ""
                            passcodeSecret set null
                            passcode set ""
                        } catch (e: LsErrorException) {
                            alert(Strings.logInError, e.error.message)
                        } catch (e: Exception) {
                            Exception("Failed to confirm passcode", e).report()
                            alert(Strings.logInError, Strings.anUnknownErrorOccurredIsYourInternetConnection)
                        }
                    }

                    text {
                        align = Align.Center
                        content = Strings.anEmailWithAPasscodeWasSentTo(email.value)
                    }

                    val field: TextField
                    label {
                        content = Strings.passcode
                        fieldTheme - textField {
                            field = this
                            hint = "------"
                            content bind passcode
                            keyboardHints = KeyboardHints.id
                            reactiveScope {
                                if (passcodeSecret() != null) requestFocus()
                            }
                        }
                    }
                    space()
                    important - button {
                        ::enabled { passcodeSecret() == usePassword || passcode().length == 6 }
                        text {
                            align = Align.Center
                            content = Strings.confirmPasscode
                        }
                        onClickAssociatedField(field, action = ::confirmPasscode)
                    }
                }
                onlyWhen { stage() == LoginScreenStage.CreateAccount } - col {
                    val createUser = Action("Submit", Icon.done) {
                        currentSession().users[currentUser.awaitNotNull()._id].modify(modification {
                            it.name assign name()
                            it.phoneNumber assign phoneNumber()
                            it.address assign Address.EMPTY
                        })
                        screenNavigator.reset(DashboardScreen())
                    }
                    text {
                        align = Align.Center
                        content = Strings.accountDoesNotExistFor(email.value)
                    }
                    field(Strings.name2) {
                        textInput {
                            keyboardHints = KeyboardHints.title
                            hint = Strings.name1
                            content bind name
                        }
                    }
                    field(Strings.phoneNumber1) {
                        textInput {
                            keyboardHints = KeyboardHints.phone
                            hint = Strings.phoneNumber
                            content bind phoneNumber
                            action = createUser
                        }
                    }
                    field(Strings.city1) {
                        textInput {
                            keyboardHints = KeyboardHints.title
                            hint = Strings.city
                            content bind address.lensPath { it.city }.nullToBlank()
                        }
                    }
                    field(Strings.state1) {
                        select {
                            bind(
                                address.lensPath { it.state },
                                Constant(listOf(null) + UsState.entries.toList())
                            ) { it?.text ?: "Pick one" }
                        }
                    }
                    field(Strings.zip1) {
                        textInput {
                            keyboardHints = KeyboardHints.integer
                            hint = Strings.zip
                            content bind address.lensPath { it.zip }.nullToBlank()
                        }
                    }
                    important - button {
                        ::enabled {
                            name().isNotEmpty() &&
                                    phoneNumber().matches(phonePattern) &&
                                    address().run {
                                        !city.isNullOrEmpty() &&
                                                state != null &&
                                                zip?.length == 5
                                    }
                        }
                        text {
                            align = Align.Center
                            content = Strings.createAccount
                        }
                        action = createUser
                    }
                }
                onlyWhen { stage() == LoginScreenStage.EnterEmail } - button {
                    text {
                        ::exists { loginView() }
                        align = Align.Center
                        content = "Create Account"
                    }
                    text {
                        ::exists { !loginView() }
                        align = Align.Center
                        content = "Login"
                    }
                    action = Action("login/createAccount", Icon.done) {
                        loginView.set(!loginView())
                    }
                }
                externalLink {
                    text {
                        align = Align.Center
                        content = Strings.support
                    }
                    to = "mailto:support@lussotechnologies.com"
                }
                link {
                    text {
                        align = Align.Center
                        content = Strings.termsOfUse
                    }
                    to = { TermsOfUseScreen() }
                }
                link {
                    text {
                        align = Align.Center
                        content = Strings.privacyPolicy
                    }
                    to = { PrivacyPolicyScreen() }
                }

                button {
                    ::exists { stage() == LoginScreenStage.EnterOTP }
                    val showIcon = Property(false)
                    onClick {
                        sendPin()
                        launchGlobal {
                            showIcon.value = true
                            delay(3000)
                            showIcon.value = false
                        }
                    }
                    centered - row {
                        centered - text {
                            align = Align.Center
                            content = Strings.sendMeANewCode
                        }
                        centered - onlyWhen { showIcon() } - icon {
                            source = Icon.done.copy(width = 1.rem, height = 1.rem)
                            description = Strings.done
                        }
                    }
                }

                button {
                    ::exists { stage() == LoginScreenStage.EnterOTP }
                    onClick {
                        passcodeSecret.value = null
                        passcode.value = ""
                    }
                    text {
                        align = Align.Center
                        content = Strings.useADifferentEmail
                    }
                }
                expanding - space()
                centered - row {
                    centered - image {
                        source = Resources.lussoLogo(defaultAppTheme)
                        description = Strings.lusso
                    }
                    space()
                    centered - h6("© 2024")
                }
            }
        }

        unpadded - stack {
            spacing = 0.px
            expanding - video {
                source = Resources.loginBackVideo
                showControls = false
                loop = true
                scaleType = ImageScaleType.Crop
                launch {
                    volume set 0f
                    playing set true
                }
            }
            mainCol()
            button {
                text {
                    align = Align.Center
                    ::content{
                        selectedApi().apiName
                    }
                } in centered in sizedBox(SizeConstraints(minWidth = 8.rem, minHeight = 2.rem))

                onClick {
                    if (email.value.endsWith("@lightningkite.com") || email.value.endsWith("@ilusso.com") || email.value.endsWith(
                            "@yourptg.com"
                        )
                    ) {
                        var newIndex = ApiOption.selectedIndex + 1
                        if (newIndex >= ApiOption.entries.size) {
                            newIndex = 0
                        }
                        ApiOption.selectedIndex = newIndex
                        selectedApi.set(ApiOption.entries[newIndex])
                    }
                }
            } in atTopEnd

            reactiveScope {
                backend()?.let { domain ->
                    ApiOption.values().find { it.api.httpUrl.contains(domain, true) }?.let {
                        selectedApi.value = it
                    }
                }
                val api = selectedApi().api
                val proof = proof() ?: return@reactiveScope
                launch {
                    val parsed = try {
                        DefaultJson.decodeFromString<Proof>(proof)
                    } catch (e: Exception) {
                        Exception("Failed to parse passed proof ${proof.take(10)}", e).report()
                        return@launch
                    }
                    val session = api.userAuth.logIn(listOf(parsed))
                    sessionToken.set(session.session ?: throw Exception("This shouldn't be null. A"))
                    delay(10)
                    navigator.replace(DashboardScreen())
                }
                launch {
                    this@LogInScreen.proof set null
                }
            }
        }
    }

    suspend fun sendEmail(login: Boolean): Notice? {
        if (email() == "joseph+appstoretester@lightningkite.com") {
            passcodeSecret.value = usePassword
        } else {
            val exists = selectedApi.await().api.checkIfUserExistsByEmail(email.await())
            if (login && !exists) {
                return Notice.UserDoesNotExist
            } else if (!login && exists) {
                return Notice.UserAlreadyExists
            }
            val passcodeResult = selectedApi.await().api.emailProof.beginEmailOwnershipProof(email.await())
            passcodeSecret.value = passcodeResult
        }
        return null
    }

    suspend fun sendPin() {
        if (email() == "joseph+appstoretester@lightningkite.com") {
            passcodeSecret.value = usePassword
        } else {
            val passcodeResult = selectedApi.await().api.emailProof.beginEmailOwnershipProof(email.await())
            passcodeSecret.value = passcodeResult
        }
    }
}