package com.ilussobsa

import com.lightningkite.kiteui.views.*
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLScriptElement
import kotlin.js.Promise
import kotlin.js.json


actual class AuctioneerView actual constructor(context: RContext) : RView(context) {
    var unityInstance:dynamic = null
    init {
        native.tag = "div"
        native.classes.add("auctioneer-view")

        native.appendChild(FutureElement().apply{
            tag = "div"
            classes.add("unity-desktop")
            id = "unity-container"
            appendChild( FutureElement().apply {
                tag = "canvas"
                id ="unity-canvas"
                attributes.widthInt = 500
                attributes.heightInt = 450
            })
        })
        native.onElement {
            val buildUrl  = "/auctioneer-animation"
            val loaderUrl = "$buildUrl/auctioneer.loader.js"
            val config =  json(
                "dataUrl" to "$buildUrl/auctioneer.data",
                "frameworkUrl" to "$buildUrl/auctioneer.framework.js",
                "codeUrl" to "$buildUrl/auctioneer.wasm",
                "streamingAssetsUrl" to "StreamingAssets",
                "companyName" to "KevuruGames",
                "productName" to "IlussoClient",
                "productVersion" to "0.08"
            )
            val script = document.createElement("script") as HTMLScriptElement
            script.src = loaderUrl
            script.onload = {
                val instancePromise = (window.asDynamic()).createUnityInstance(document.querySelector("#unity-canvas"), config) { progress: Float ->
                    console.log(progress)
                    Unit
                } as Promise<dynamic>

                instancePromise.then {instance:dynamic->
                    unityInstance = instance
                }
                Unit
            }
            it.append(script)
        }
    }

    actual var animationAction: AnimationAction
        get() = TODO("Not yet implemented")
        set(value) {
            if(unityInstance!= null && value != AnimationAction.IDLE) {
                unityInstance.SendMessage("HooksCatcher",value.action)
            }
        }
}