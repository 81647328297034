package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.currentSessionNullable
import com.ilussobsa.sdk.currentSession
import com.ilussobsa.sdk.currentUser
import com.ilussobsa.utils.*
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.KiteUiScreen
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.lightningserver.websocket.*
import com.lightningkite.serialization.*
import kotlinx.coroutines.*


@Routable("/settings")
class SettingsScreen() : KiteUiScreen {
    val userInProgress = Property<User>(User(email = ""))
    val startUser = Property<User>(User(email = ""))
    override val title: Readable<String> = shared {
        listOfNotNull(
            Strings.settings,
        ).joinToString(" ")
    }

    override fun ViewWriter.render() {
        scrolls - col {
            launch {
                startUser set currentUser.awaitNotNull()
                userInProgress set currentUser.awaitNotNull()
            }

            card - col {
                h3(Strings.accountInformation)
                padded - col {

                    centered - button {
                        profilePicture(userInProgress)
                        onClick {
                            ExternalServices.requestFile(listOf("image/png", "image/jpeg")) { image ->
                                if (image == null) return@requestFile
                                launch {
                                    val session = currentSessionNullable.await() ?: return@launch
                                    try {
                                        val futureCallToken =
                                            session.uploadFileForRequest().upload(RequestBodyFile(image))
                                        tempImagePointers[futureCallToken] = ImageLocal(image)
                                        userInProgress.modify { it.copy(profilePicture = futureCallToken) }
                                    } catch (e: Exception) {
                                        Exception("Error updating profile picture", e).report()
                                        alert(Strings.error, Strings.failedToUploadPicture)
                                    }
                                }
                            }
                        }
                    }

                    centered - row {
                        // TODO: sizeConstraints(width = 8.rem) not working on iOS
                        centered - sizeConstraints(width = 8.rem) - text(Strings.email1)
                        sizeConstraints(width = 15.rem) - fieldTheme - disabled - textField {
                            enabled = false
                            content bind userInProgress.prop(User.path.email)
                        }
                    }
                    centered - row {
                        centered - sizeConstraints(width = 8.rem) - text(Strings.phoneNumber)
                        sizeConstraints(width = 15.rem) - fieldTheme - textField {
                            content bind userInProgress.prop(User.path.phoneNumber).notNull("")
                        }
                    }
                    centered - row {
                        centered - sizeConstraints(width = 8.rem) - text(Strings.name2)
                        sizeConstraints(width = 15.rem) - fieldTheme - textField {

                            content bind userInProgress.prop(User.path.name)
                        }
                    }


                    gravity(Align.End, Align.Center) - compact - important - button {
                        text {
                            ::content {
                                if (userInProgress() != startUser()) Strings.save else Strings.saved
                            }
                        }
                        ::enabled {
                            userInProgress() != startUser()
                        }
                        onClick {
                            launch {
                                val session = currentSessionNullable.await() ?: return@launch
                                val modification = modification<User> {
                                    it.email assign userInProgress.await().email
                                    it.phoneNumber assign userInProgress.await().phoneNumber
                                    it.name assign userInProgress.await().name
                                    it.profilePicture assign userInProgress.await().profilePicture
                                }
                                try {
                                    currentSession().users[currentSession().userId.await()].modify(modification)?.let { startUser set it }
                                } catch (e: Exception) {
                                    alert(Strings.error, Strings.updateFailed1)
                                }
                            }
                        }
                    }
                }
            }

            card - col {
                h3(Strings.preferences)
                h6(Strings.navigation)
                scrollsHorizontally - row {
                    NavPreference.values().forEach {
                        radioToggleButton {
                            ::exists{ it.worksForSmall || AppState.windowInfo().width > 1000.dp }
                            centered - text(it.title)
                            checked bind appNavPreference.equalTo(it)
                        }
                    }
                }
                h6("Tab Behavior")
                scrollsHorizontally - row {
                    TabBehavior.values().forEach {
                        radioToggleButton {
                            centered - text(it.title)
                            checked bind tabBehavior.equalTo(it)
                        }
                    }
                }
                padded - row {
                    centered - expanding - text("Use new menu")
                    switch {
                        checked bind useNewMenu
                    }
                }

                menuButton {
                    exists = false
//                    ::exists { currentUser()?.let { it.role >= UserRole.Manager } == true && selectedApi() != ApiOption.Prod }
                    ::exists { currentUser()?.let { it.role >= UserRole.Manager } == true }
                    text("Admin actions")
                    opensMenu {

                        col {
                            button {
                                text("Reset data")
                                onClick {
                                    closePopovers()
                                    alert(Strings.resetResult, currentSession().faker.resetTestData(ResetDataRequest(false)))
                                }
                            }
                            button {
                                text("Reset data with proxies")
                                onClick {
                                    closePopovers()
                                    alert(Strings.resetResult, currentSession().faker.resetTestData(ResetDataRequest(proxyBurstMode = true)))
                                }
                            }
                            button {
                                text("Reset data just finished")
                                onClick {
                                    closePopovers()
                                    alert(Strings.resetResult, currentSession().faker.resetTestData(ResetDataRequest(postAuction = true)))
                                }
                            }
                            button {
                                text("Clear test data")
                                onClick {
                                    closePopovers()
                                    alert(Strings.resetResult, currentSession().faker.resetTestData(ResetDataRequest(justClean = true)))
                                }
                            }
                            button {
                                text("Save auction state")
                                onClick {
                                    closePopovers()
                                    alert(Strings.resetResult, currentSession().faker.saveState())
                                }
                            }
                            button {
                                text("Load auction state")
                                onClick {
                                    closePopovers()
                                    alert(Strings.resetResult, currentSession().faker.loadState())
                                }
                            }
                            button {
                                text("Send error report in background")
                                onClick {
                                    closePopovers()
                                    Exception("Test error").report()
                                }
                            }
                            button {
                                text("Test Audio")
                                var index = 0
                                onClick {
                                    closePopovers()
                                    Auctioneer.Recording1.audioPack().next.audio.let { it[index++ % it.size] }.load().play()
                                    repeat(10) {

                                        gc()
                                    }
                                }
                            }
                            button {
                                text("GC")
                                var index = 0
                                onClick {
                                    closePopovers()
                                    repeat(10) {
                                        cleanImageCache()
                                        println(gc().usage.div(1_000_000.0).toString() + " mb")
                                    }
                                }
                            }
                        }
                    }
                }
            }

            card - col {
                h3(Strings.support)
                rowCollapsingToColumn(50.rem) {
                    expanding - card - externalLink {
                        centered - text("Email support@lussotechnologies.com")
                        to = "mailto:support@lussotechnologies.com"
                    }
//                    expanding - card - externalLink {
//                        centered - text("Call 000-000-0000")
//                        to = "tel:0000000000"
//                    }
                }
            }
        }
    }
}