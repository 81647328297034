// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareShortVehicleFields() {
    val props: Array<SerializableProperty<ShortVehicle, *>> = arrayOf(ShortVehicle__id, ShortVehicle_geoCoordinate, ShortVehicle_submitted, ShortVehicle_completion, ShortVehicle_paid, ShortVehicle_received, ShortVehicle_cancelled, ShortVehicle_counterOffer, ShortVehicle_estimatedValue, ShortVehicle_vin, ShortVehicle_year, ShortVehicle_make, ShortVehicle_model, ShortVehicle_trim, ShortVehicle_odometer, ShortVehicle_transmission, ShortVehicle_fuelType, ShortVehicle_interiorColor, ShortVehicle_exteriorColor)
    ShortVehicle.serializer().properties { props }
}
val <K> DataClassPath<K, ShortVehicle>._id: DataClassPath<K, UUID> get() = this[ShortVehicle__id]
val <K> DataClassPath<K, ShortVehicle>.geoCoordinate: DataClassPath<K, GeoCoordinate> get() = this[ShortVehicle_geoCoordinate]
val <K> DataClassPath<K, ShortVehicle>.submitted: DataClassPath<K, Instant?> get() = this[ShortVehicle_submitted]
val <K> DataClassPath<K, ShortVehicle>.completion: DataClassPath<K, Completion?> get() = this[ShortVehicle_completion]
val <K> DataClassPath<K, ShortVehicle>.paid: DataClassPath<K, Instant?> get() = this[ShortVehicle_paid]
val <K> DataClassPath<K, ShortVehicle>.received: DataClassPath<K, Instant?> get() = this[ShortVehicle_received]
val <K> DataClassPath<K, ShortVehicle>.cancelled: DataClassPath<K, Instant?> get() = this[ShortVehicle_cancelled]
val <K> DataClassPath<K, ShortVehicle>.counterOffer: DataClassPath<K, PriceInDollars?> get() = this[ShortVehicle_counterOffer]
val <K> DataClassPath<K, ShortVehicle>.estimatedValue: DataClassPath<K, PriceInDollars> get() = this[ShortVehicle_estimatedValue]
val <K> DataClassPath<K, ShortVehicle>.vin: DataClassPath<K, String> get() = this[ShortVehicle_vin]
val <K> DataClassPath<K, ShortVehicle>.year: DataClassPath<K, Short?> get() = this[ShortVehicle_year]
val <K> DataClassPath<K, ShortVehicle>.make: DataClassPath<K, String?> get() = this[ShortVehicle_make]
val <K> DataClassPath<K, ShortVehicle>.model: DataClassPath<K, String?> get() = this[ShortVehicle_model]
val <K> DataClassPath<K, ShortVehicle>.trim: DataClassPath<K, String?> get() = this[ShortVehicle_trim]
val <K> DataClassPath<K, ShortVehicle>.odometer: DataClassPath<K, Int?> get() = this[ShortVehicle_odometer]
val <K> DataClassPath<K, ShortVehicle>.transmission: DataClassPath<K, Transmission?> get() = this[ShortVehicle_transmission]
val <K> DataClassPath<K, ShortVehicle>.fuelType: DataClassPath<K, FuelType?> get() = this[ShortVehicle_fuelType]
val <K> DataClassPath<K, ShortVehicle>.interiorColor: DataClassPath<K, VehicleColor?> get() = this[ShortVehicle_interiorColor]
val <K> DataClassPath<K, ShortVehicle>.exteriorColor: DataClassPath<K, VehicleColor?> get() = this[ShortVehicle_exteriorColor]
inline val ShortVehicle.Companion.path: DataClassPath<ShortVehicle, ShortVehicle> get() = path<ShortVehicle>()


object ShortVehicle__id: SerializableProperty<ShortVehicle, UUID> {
    override val name: String = "_id"
    override fun get(receiver: ShortVehicle): UUID = receiver._id
    override fun setCopy(receiver: ShortVehicle, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid()
}
object ShortVehicle_geoCoordinate: SerializableProperty<ShortVehicle, GeoCoordinate> {
    override val name: String = "geoCoordinate"
    override fun get(receiver: ShortVehicle): GeoCoordinate = receiver.geoCoordinate
    override fun setCopy(receiver: ShortVehicle, value: GeoCoordinate) = receiver.copy(geoCoordinate = value)
    override val serializer: KSerializer<GeoCoordinate> = GeoCoordinate.serializer()
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("geoCoordinate")
}
object ShortVehicle_submitted: SerializableProperty<ShortVehicle, Instant?> {
    override val name: String = "submitted"
    override fun get(receiver: ShortVehicle): Instant? = receiver.submitted
    override fun setCopy(receiver: ShortVehicle, value: Instant?) = receiver.copy(submitted = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("submitted")
    override val default: Instant? = null
}
object ShortVehicle_completion: SerializableProperty<ShortVehicle, Completion?> {
    override val name: String = "completion"
    override fun get(receiver: ShortVehicle): Completion? = receiver.completion
    override fun setCopy(receiver: ShortVehicle, value: Completion?) = receiver.copy(completion = value)
    override val serializer: KSerializer<Completion?> = Completion.serializer().nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("completion")
    override val default: Completion? = null
}
object ShortVehicle_paid: SerializableProperty<ShortVehicle, Instant?> {
    override val name: String = "paid"
    override fun get(receiver: ShortVehicle): Instant? = receiver.paid
    override fun setCopy(receiver: ShortVehicle, value: Instant?) = receiver.copy(paid = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("paid")
    override val default: Instant? = null
}
object ShortVehicle_received: SerializableProperty<ShortVehicle, Instant?> {
    override val name: String = "received"
    override fun get(receiver: ShortVehicle): Instant? = receiver.received
    override fun setCopy(receiver: ShortVehicle, value: Instant?) = receiver.copy(received = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("received")
    override val default: Instant? = null
}
object ShortVehicle_cancelled: SerializableProperty<ShortVehicle, Instant?> {
    override val name: String = "cancelled"
    override fun get(receiver: ShortVehicle): Instant? = receiver.cancelled
    override fun setCopy(receiver: ShortVehicle, value: Instant?) = receiver.copy(cancelled = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("cancelled")
    override val default: Instant? = null
}
object ShortVehicle_counterOffer: SerializableProperty<ShortVehicle, PriceInDollars?> {
    override val name: String = "counterOffer"
    override fun get(receiver: ShortVehicle): PriceInDollars? = receiver.counterOffer
    override fun setCopy(receiver: ShortVehicle, value: PriceInDollars?) = receiver.copy(counterOffer = value)
    override val serializer: KSerializer<PriceInDollars?> = PriceInDollars.serializer().nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("counterOffer")
    override val default: PriceInDollars? = null
}
object ShortVehicle_estimatedValue: SerializableProperty<ShortVehicle, PriceInDollars> {
    override val name: String = "estimatedValue"
    override fun get(receiver: ShortVehicle): PriceInDollars = receiver.estimatedValue
    override fun setCopy(receiver: ShortVehicle, value: PriceInDollars) = receiver.copy(estimatedValue = value)
    override val serializer: KSerializer<PriceInDollars> = PriceInDollars.serializer()
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("estimatedValue")
}
object ShortVehicle_vin: SerializableProperty<ShortVehicle, String> {
    override val name: String = "vin"
    override fun get(receiver: ShortVehicle): String = receiver.vin
    override fun setCopy(receiver: ShortVehicle, value: String) = receiver.copy(vin = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("vin")
}
object ShortVehicle_year: SerializableProperty<ShortVehicle, Short?> {
    override val name: String = "year"
    override fun get(receiver: ShortVehicle): Short? = receiver.year
    override fun setCopy(receiver: ShortVehicle, value: Short?) = receiver.copy(year = value)
    override val serializer: KSerializer<Short?> = Short.serializer().nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("year")
    override val default: Short? = null
}
object ShortVehicle_make: SerializableProperty<ShortVehicle, String?> {
    override val name: String = "make"
    override fun get(receiver: ShortVehicle): String? = receiver.make
    override fun setCopy(receiver: ShortVehicle, value: String?) = receiver.copy(make = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("make")
    override val default: String? = null
}
object ShortVehicle_model: SerializableProperty<ShortVehicle, String?> {
    override val name: String = "model"
    override fun get(receiver: ShortVehicle): String? = receiver.model
    override fun setCopy(receiver: ShortVehicle, value: String?) = receiver.copy(model = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("model")
    override val default: String? = null
}
object ShortVehicle_trim: SerializableProperty<ShortVehicle, String?> {
    override val name: String = "trim"
    override fun get(receiver: ShortVehicle): String? = receiver.trim
    override fun setCopy(receiver: ShortVehicle, value: String?) = receiver.copy(trim = value)
    override val serializer: KSerializer<String?> = String.serializer().nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("trim")
    override val default: String? = null
}
object ShortVehicle_odometer: SerializableProperty<ShortVehicle, Int?> {
    override val name: String = "odometer"
    override fun get(receiver: ShortVehicle): Int? = receiver.odometer
    override fun setCopy(receiver: ShortVehicle, value: Int?) = receiver.copy(odometer = value)
    override val serializer: KSerializer<Int?> = Int.serializer().nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("odometer")
    override val default: Int? = null
}
object ShortVehicle_transmission: SerializableProperty<ShortVehicle, Transmission?> {
    override val name: String = "transmission"
    override fun get(receiver: ShortVehicle): Transmission? = receiver.transmission
    override fun setCopy(receiver: ShortVehicle, value: Transmission?) = receiver.copy(transmission = value)
    override val serializer: KSerializer<Transmission?> = Transmission.serializer().nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("transmission")
    override val default: Transmission? = null
}
object ShortVehicle_fuelType: SerializableProperty<ShortVehicle, FuelType?> {
    override val name: String = "fuelType"
    override fun get(receiver: ShortVehicle): FuelType? = receiver.fuelType
    override fun setCopy(receiver: ShortVehicle, value: FuelType?) = receiver.copy(fuelType = value)
    override val serializer: KSerializer<FuelType?> = FuelType.serializer().nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("fuelType")
    override val default: FuelType? = null
}
object ShortVehicle_interiorColor: SerializableProperty<ShortVehicle, VehicleColor?> {
    override val name: String = "interiorColor"
    override fun get(receiver: ShortVehicle): VehicleColor? = receiver.interiorColor
    override fun setCopy(receiver: ShortVehicle, value: VehicleColor?) = receiver.copy(interiorColor = value)
    override val serializer: KSerializer<VehicleColor?> = VehicleColor.serializer().nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("interiorColor")
    override val default: VehicleColor? = null
}
object ShortVehicle_exteriorColor: SerializableProperty<ShortVehicle, VehicleColor?> {
    override val name: String = "exteriorColor"
    override fun get(receiver: ShortVehicle): VehicleColor? = receiver.exteriorColor
    override fun setCopy(receiver: ShortVehicle, value: VehicleColor?) = receiver.copy(exteriorColor = value)
    override val serializer: KSerializer<VehicleColor?> = VehicleColor.serializer().nullable2
    override val annotations: List<Annotation> = ShortVehicle.serializer().tryFindAnnotations("exteriorColor")
    override val default: VehicleColor? = null
}
