package com.ilussobsa

import com.lightningkite.Distance
import com.lightningkite.kiteui.locale.RenderSize
import com.lightningkite.kiteui.locale.renderDateToString
import com.lightningkite.kiteui.locale.renderTimeToString
import kotlinx.datetime.Instant

interface StringsBase {


    val appName: String get() = "Brand Specific Auctions"
    val joinAdvertisement: String get() = "Welcome to Brand Specific Auctions. Please complete and submit the following registration information."
    val requestAccount: String get() = "Request an Account"
    val latitude: String get() = "Latitude"
    val longitude: String get() = "Longitude"

    // Dealership
    val dealership: String get() = "Dealership"
    val dealershipGroup: String get() = "Dealership Group"
    val dealershipProfile: String get() = "Dealership Profile"
    val dealerships: String get() = "Dealerships"
    val pleaseSelectAUserOnTheLeft: String get() = "Please select a user on the left!"
    val noUsersMatchedTheseConditions: String get() = "No user matched these conditions."
    fun totalPurchasesX(x: Any?): String = "Total Purchases: ${x}"
    fun totalSalesX(x: Any?): String = "Total Sales: ${x}"
    fun youAndXOthersMarkedThisDealershipAs(x: Any?): String =
        "You and ${x} others marked this dealership as preferred."
    fun xMarkedThisDealershipAs(x: Any?): String =
        "${x} dealerships marked this as preferred."
    val thisDealershipHasntBeenMarkedAsPreferredYet: String get() = "This dealership hasn't been marked as preferred yet."
    fun preferredByXOtherDealerships(x: Int): String = "Preferred by $x dealerships"
    val preferredDealershipQuestionMark: String get() = "Preferred Dealership?"
    val preferredDealership2: String get() = "Preferred Dealership"
    val preferredDealershipsOnly: String get() = "Preferred dealerships only"
    val preferredOnly: String get() = "Preferred Only"
    val rightHere: String get() = "Right here"
    fun xMilesAway(distance: Distance) = "${distance.miles.toInt().toCommaString()} miles away"
    val newDealership: String get() = "New Dealership"
    val profilePicture: String get() = "Profile Picture"
    val unknownDealership: String get() = "Unknown dealership"
    val vehiclesPurchased: String get() = "Vehicles Purchased"
    val vehiclesSold: String get() = "Vehicles Sold"
    val youCantRemoveYourselfFromTheDealership: String get() = "You can't remove yourself from the dealership."
    fun cancelledSales(num: Int): String = "$num Cancelled Sales"
    fun cancelledBuys(num: Int): String = "$num Cancelled Buys"

    // Dealership Requests
    val selectPaymentMethod: String get() = "Select Payment Method"
    val creditCard: String get() = "Credit Card"
    val check: String get() = "Check"
    val applicationSubmitSuccess: String get() = "Thank you for your application."
    val associateWillReview: String get() = "An associate will review your application and get back to you."
    val rejectRequest: String get() = "Reject Request"
    val rejectRequestAreYouSure: String get() = "Are you sure you wish to reject this request?"

    // User Verification Form
    val verification: String get() = "Verification"
    val whatBank: String get() = "What bank?"
    val howMuch: String get() = "How much are you approved for?"
    val howLong: String get() = "How many month term?"
    val whatInterestRate: String get() = "What interest rate?"
    val verificationThankYou: String get() = "You are verified and ready to participate in auctions!"

    // Roles
    val youCantRemoveManagersFromTheDealershipMove: String get() = "You can't remove managers from the dealership.  Move their roles to a different user first."
    val titleManager: String get() = "Title Manager"
    val usedCarManager: String get() = "Used Car Manager"
    val billingContact: String get() = "Billing Contact"
    val thisUserIsATitleManager: String get() = "This user is a Title Manager."
    val thisUserIsATitleManagerAndA: String get() = "This user is a Title Manager and a Used Car Manager."
    val thisUserIsAUsedCarManager: String get() = "This user is a Used Car Manager."

    // Grades
    fun accuracyGradeX(x: Any?): String = "Accuracy Grade: ${x}"
    fun buyingTransactionGradeX(x: Any?): String = "Buying Transaction Grade: ${x}"
    fun sellingTransactionGradeX(x: Any?): String = "Selling Transaction Grade: ${x}"

    val buyingGrade: String get() = "Buying Grade"
    val sellingGrade: String get() = "Selling Grade"
    val accuracyGrade: String get() = "Accuracy Grade"
    val accuracy: String get() = "Accuracy"
    val sellingTransaction: String get() = "Selling Transaction"
    val buyingTransaction: String get() = "Buying Transaction"

    fun fullTextSellingTransaction(grade: Grade): String = when (grade) {
        Grade.Unknown -> "This dealership does not have much experience on the platform yet."
        Grade.C -> "This dealership has had multiple challenges when selling a vehicle."
        Grade.B -> "This dealership has had some past issues when selling a vehicle."
        Grade.A -> "This dealership is reputable as being easy to work with when selling a vehicle."
    }

    fun fullTextBuyingTransaction(grade: Grade): String = when (grade) {
        Grade.Unknown -> "This dealership does not have much experience on the platform yet."
        Grade.C -> "This dealership has had multiple challenges when buying a vehicle."
        Grade.B -> "This dealership has had some past issues when buying a vehicle."
        Grade.A -> "This dealership is reputable as being easy to work with when buying a vehicle."
    }

    fun fullTextAccuracy(grade: Grade): String = when (grade) {
        Grade.Unknown -> "This dealership does not have much experience on the platform yet."
        Grade.C -> "This dealership has had multiple issues with accurately describing their vehicles."
        Grade.B -> "This dealership has had some issues with accurately describing their vehicles."
        Grade.A -> "This dealership is reputable with their accurate vehicle descriptions."
    }

    val ratingHintAccuracy: String get() = "This rating represents a dealership's history of accurately representing the vehicles they sell on the platform. It is adjusted based on feedback from buyers who have made purchases from the dealership. An 'A' rating denotes the highest level of accuracy, while a 'B' indicates some past issues. A 'C' rating reflects multiple historical issues. Dealerships can improve their rating by completing more transactions without any reported problems."
    val ratingHintSelling: String get() = "This rating reflects a dealership's historical performance on the platform, specifically evaluating the ease of transaction when selling a vehicle. It considers factors such as the simplicity of the payment process and the convenience of vehicle pickup. An 'A' rating signifies that the dealership is prompt and easy to work with, while a 'B' rating indicates some past issues. A 'C' rating denotes multiple historical challenges. Dealerships can enhance their rating by consistently completing transactions without reported issues"
    val ratingHintBuying: String get() = "This rating reflects a dealership's historical performance on the platform, specifically evaluating the ease of transaction when buying a vehicle. It considers factors such as the simplicity paying for the vehicle and getting the vehicle picked up. An 'A' rating signifies that the dealership is prompt and easy to work with, while a 'B' rating indicates some past issues. A 'C' rating denotes multiple historical challenges. Dealerships can enhance their rating by consistently completing transactions without reported issues"

    // Dealership's Vehicles
    val recentlyPurchased: String get() = "Recently Purchased"
    val recentlySold1: String get() = "Recently sold"

    // Authentication

    fun anEmailWithAPasscodeWasSentTo(x: Any?): String =
        "An email with a passcode was sent to you. Enter the passcode below to login. If you haven't received the email, remember to check your spam folder."
    val logIn: String get() = "Log In"
    val logInError: String get() = "Log In Error"
    val refresh: String get() = "Refresh"
    val logOut: String get() = "Log Out"
    val sendALoginCode: String get() = "Send a Login Code"
    val confirmPasscode: String get() = "Confirm Passcode"
    val useADifferentEmail: String get() = "Use a different email"
    val passcode: String get() = "Passcode"
    val sendMeANewCode: String get() = "Send me a new code"
    fun signInWithX(x: Any?): String = "Sign In with ${x}"
    val microsoft: String get() = "Microsoft"
    val apple: String get() = "Apple"
    val google: String get() = "Google"
    val createAccount: String get() = "Create Account"
    fun accountDoesNotExistFor(x: Any?): String = "Create an account to begin."

    // Tutorials
    val tutorials: String get() = "Tutorials"
    fun tutorialsXY(x: Any?, y: Any?): String = "Tutorials (${x} / ${y})"
    fun welcomeChecklistXY(x: Any?, y: Any?): String = "Welcome Checklist (${x} / ${y})"

    // Accounts
    val email1: String get() = "Email:"
    val email2: String get() = "Email"
    val name1: String get() = "Name"
    val name2: String get() = "Name:"
    val phone: String get() = "Phone"
    val phoneNumber: String get() = "Phone Number:"
    val phoneNumber1: String get() = "Phone Number:"
    fun welcomeBackX(x: Any?): String = "Welcome back, ${x}!"
    val accountInformation: String get() = "My Profile"
    val addAUser: String get() = "Add a User"
    val myAccount: String get() = "My Account"
    fun thisActionWillRemoveTheUserFromX(x: Any?): String = "This action will remove the user from ${x}."
    val editUser: String get() = "Edit User"
    val removeTheUser: String get() = "Remove the user"
    val removeUser: String get() = "Remove User"
    val thereWasAnErrorAddingThisUser: String get() = "There was an error adding this user."
    val thereWasAnErrorRemovingTheUser: String get() = "There was an error removing the user."
    val thisUserDoesNotHaveAPositionAssigned: String get() = "This user does not have a position assigned to them."
    val updateThisUsersPositionBySelectingTheCorrect: String get() = "Update this user's position by selecting the correct option(s) below."
    val user: String get() = "User"
    val users: String get() = "Users"
    val howWillYouPay: String get() = "If you are the highest bidder, how will you be paying?"
    val financingSource: String get() = "Financing Source"
    fun financingSourceForm(x: FinancingSource) = when (x) {
        FinancingSource.Cash -> "Cash"
        FinancingSource.OwnFinancing -> "I have my own financing"
        FinancingSource.IlussoFinancing -> "I will get financed through iLusso"
    }
    fun financingSourceName(x: FinancingSource?) = when (x) {
        null -> "No financing"
        FinancingSource.Cash -> "Cash"
        FinancingSource.OwnFinancing -> "Financed through external bank"
        FinancingSource.IlussoFinancing -> "Financed through iLusso"
    }
    val accountStatus: String get() = "Account Status"
    fun userRoleNames(x: UserRole) = when (x) {
        UserRole.Anonymous -> "Anonymous"
        UserRole.Disabled -> "Disabled"
        UserRole.UnverifiedCustomer -> "Unverified Customer"
        UserRole.Customer -> "Customer"
        UserRole.Manager -> "Manager"
        UserRole.Admin -> "Admin"
        UserRole.Root -> "Root"
        UserRole.Developer -> "Developer"
    }

    val preferences: String get() = "Preferences"

    val navigation: String get() = "Navigation"
    val appwideNavigation: String get() = "App-wide Navigation"
    val alwaysOpen: String get() = "Always Open"
    val top: String get() = "Top"
    val collapsible: String get() = "Collapsible"
    val bottomTabs: String get() = "Bottom Tabs"

    val theme: String get() = "Theme"
    fun swapThemeToX(x: Any?): String = "Swap theme to ${x}"
    val dark2: String get() = "Dark2"
    val dark: String get() = "Dark"
    val grayscaleDark: String get() = "Grayscale Dark"
    val grayscaleLight: String get() = "Grayscale Light"
    val light: String get() = "Light"

    // Notifications
    val inApp: String get() = "In-App"
    val notificationSettings: String get() = "Notification Settings"
    val notifications: String get() = "Notifications"
    val notificationsEnabled: String get() = "Notifications Enabled"
    val sendATestNotification: String get() = "Send a Test Notification"
    val sendATestNotificationToDealership: String get() = "Send a Test Notification to the whole dealership"

    // Transport
    fun transportAcceptedForX(x: Any?): String = "Transport Accepted for ${x}"
    fun transportCompletedForX(x: Any?): String = "Transport Completed for ${x}"
    fun transportDeclinedForX(x: Any?): String = "Transport Declined for ${x}"
    fun transportForX(x: Any?): String = "Transport for ${x}"
    val noTransportRequestsToShow: String get() = "No transport requests to show."
    val pleaseSelectATransportQuoteRequestOnThe: String get() = "Please select a transport quote request on the left!"
    val requestTransportQuote: String get() = "Request Transport Quote"
    val transportContact: String get() = "Transport Contact"
    val transportQuote: String get() = "Transport Quote"
    val transportQuotePending: String get() = "Transport Quote Pending"
    val transportQuotes: String get() = "Transport Quotes"
    val transportRequest: String get() = "Transport Request"
    val transportCompleted: String get() = "Completed"
    val transportDeclined: String get() = "Declined"
    val business: String get() = "Business"
    val contactEmail: String get() = "Contact Email"
    val contactName: String get() = "Contact Name"
    val contactPhone: String get() = "Contact Phone"
    val decline: String get() = "Decline"
    val deliveryNotes1: String get() = "Delivery Notes"
    val deliveryNotes2: String get() = "Delivery notes..."
    val pickup: String get() = "Pickup"
    val dropOff1: String get() = "Drop Off"
    val dropOff2: String get() = "Drop off"
    val quoted: String get() = "Quoted"
    val reject: String get() = "Reject"
    val rejected: String get() = "Rejected"
    val requested: String get() = "Requested"
    val unquoted: String get() = "Unquoted"
    val viewQuote: String get() = "View Quote"
    val workOrder: String get() = "Work Order"
    val quoteStatus: String get() = "Quote Status"
    val platformFee: String get() = "Platform fee"
    val submitTransportRequest: String get() = "Submit Request"

    // Search
    val yearRangeMinLabel: String get() = "From"
    val yearRangeMaxLabel: String get() = "To"
    val removeSearch: String get() = "Remove Search"
    val newSearch: String get() = "New Search"
    val clearSearch: String get() = "Clear search"
    val saveSearch: String get() = "Save Search"
    val savedSearch: String get() = "Saved Search"
    val findVehicles: String get() = "Find Vehicles"
    val search: String get() = "Search"
    val searchName: String get() = "Search Name"
    val searchVehicles: String get() = "Search Vehicles"
    val thereWasAnErrorRemovingTheSavedSearch: String get() = "There was an error removing the saved search."
    val thereWasAnErrorUpdatingThisSearch: String get() = "There was an error updating this search."
    fun withinXMiles(x: Any?): String = "Within ${x} miles"
    fun lessThanOdometer(x: Any?): String = "Less than ${x}"
    val addMakes: String get() = "Add Makes"
    val addModels: String get() = "Add Models"
    val all: String get() = "All"
    val anywhere: String get() = "Anywhere"

    // Vehicle Status
    val runningInNextAuction: String get() = "Running in next auction"
    fun auctionStartsX(x: Any?): String = "Auction starts ${x}"
    fun counterOfferOfXDeclined(x: Any?): String = "Counter offer of ${x} declined"
    fun counteredWithX(x: Any?): String = "Countered with ${x}"
    fun declinedCounterOfferOfX(x: Any?): String = "Declined counter offer of ${x}"
    fun declinedOfferOfX(x: Any?): String = "Declined offer of ${x}"
    fun highBidOfX(x: Any?): String = "High bid of ${x}"
    fun offerOfXDeclined(x: Any?): String = "Offer of ${x} declined"
    fun openOfferOfX(x: Any?): String = "Open offer of ${x}"
    fun proxyBidUpToX(x: Any?): String = "Proxy Bid up to ${x}"
    fun purchasedAtX(x: Any?): String = "Purchased at ${x}"
    fun receivedCounterOfferOfX(x: Any?): String = "Received counter offer of ${x}"
    fun soldAtX(x: Any?): String = "Sold at ${x}"
    fun soldForX(x: Any?): String = "Sold for ${x}"
    fun youOfferedXWaitingOnSeller(x: Any?): String = "You offered ${x}"
    val dealCancelled: String get() = "Deal was cancelled"
    val draft: String get() = "Draft"
    val liveNow: String get() = "Live now"
    val noBids: String get() = "No bids"
    val archive: String get() = "Archive"
    val archived: String get() = "Archived"
    val unarchive: String get() = "Unarchive"

    fun offerExpiresX(x: Any?): String = "Offer expires ${x}"

    // Live Auction
    val viewRunlist: String get() = "View Runlist"
    val liveAuctionsToday: String get() = "Live Auctions Today"
    val liveAuctions: String get() = "Live Auctions"
    val allAuctionsAreRunEveryFridayAtThe: String get() = "All auctions are run every Friday at the times listed below."
    val auctionsAreRunningTodayAtTheTimesListed: String get() = "Auctions are running today at the times listed below."
    val auctionsAreScheduled: String get() = "Auctions are scheduled to run at the times listed below."

    val askingPrice: String get() = "Asking Price"
    fun bidX(x: Any?): String = "Bid ${x}"
    val youHaveTheTopBid: String get() = "You have the top bid!"
    val jumpToCurrent: String get() = "Jump to current"
    val startNow: String get() = "Start Now"
    val startTheAuction: String get() = "Start the auction"
    val theAuctionIsntLive: String get() = "The auction isn't live."
    val authorizeSale: String get() = "Authorize Sale"
    val reserveMet: String get() = "Reserve Met"
    val sendSellerMessageHint: String get() = "Send a message..."
    fun sellerSaysX(x: Any?): String = "Seller says: ${x}"
    val noSale: String get() = "No Sale"
    val offerReady: String get() = "Offer Ready"
    val goingOnce: String get() = "Going once..."
    val goingTwice: String get() = "Going twice..."
    val thankYouForYourParticipation: String get() = "Thank you for your participation!"
    val theReserveHasBeenMet: String get() = "The reserve has been met!"
    val auctioneer: String get() = "Auctioneer"
    val selectAuctioneer: String get() = "Select Auctioneer"
    val thisAuctionWillOpenSoon: String get() = "This auction will open soon."
    val thisAuctionAlreadyHappened: String get() = "This auction has already happened. Refer to the auction schedule to find another auction."
    val viewAuctionSchedule: String get() = "View auction schedule"
    fun thisAuctionScheduledToOpenOn(at: Instant): String = "This auction is scheduled to open on ${at.renderDateToString(
        RenderSize.Full)} at ${at.renderTimeToString(RenderSize.Numerical)}."
    fun yourVehicleWillBeInXOnY(x: Any?, y: Any?, z: Any?): String = "Your vehicle will be in ${x} on ${y} ${z}."

    // Offers
    val accepted: String get() = "Accepted"
    val accept: String get() = "Accept"
    val awaitingReview: String get() = "Awaiting sellers review of offer"
    val acceptCounterOffer: String get() = "Accept Counter Offer"
    fun counterOfferX(x: Any?): String = "Counter Offer: ${x}"
    val counterOffer: String get() = "Counter Offer"
    val counterOffers: String get() = "Counter Offers"
    val countered: String get() = "Countered"
    val declineCounterOffer: String get() = "Decline Counter Offer"
    val enterACounterOffer: String get() = "Enter a Counter Offer"

    // Tabs
    val receivedCounterTab: String get() = "Received Counter"
    val allTab: String get() = "All"
    val biddingHistoryTab: String get() = "Bidding History"
    val boughtTab: String get() = "Purchased"
    val completeTab: String get() = "Complete"
    val favoritesTab: String get() = "Favorites"
    val laneAlertsTab: String get() = "Lane Alerts"
    val proxyBidsTab: String get() = "Proxy Bids"

    // Statistics
    val purchaseValueTotal: String get() = "Purchase Value Total"
    val statistics: String get() = "Statistics"
    val allTime: String get() = "All Time"
    val saleValueTotal: String get() = "Sale Value Total"

    fun odometerXMi(x: Any?): String = "Odometer: ${x} mi"
    fun testNotificationX(x: Any?): String = "Test Notification ${x}"
    fun thisActionWillRemoveX(x: Any?): String = "This action will remove ${x}"

    val placeholder123PlaceStreet: String get() = "123 Place Street"

    // Vehicle Status
    val auctionWinner: String get() = "Auction Winner"
    val bid: String get() = "Bid"
    val bids: String get() = "Bids: "
    val buy: String get() = "Buy"
    val buyer: String get() = "Buyer"
    val comingNextAuction: String get() = "Coming next auction"
    val completePurchase: String get() = "Complete Purchase"
    val completeSale: String get() = "Complete Sale"
    val enterAProxyBid: String get() = "Enter a Proxy Bid"
    val failedToSubmitProxyBid: String get() = "Failed to submit proxy bid."
    val markComplete: String get() = "Mark Complete"
    val markTransactionComplete: String get() = "Mark Transaction Complete"
    val paymentReceived: String get() = "Payment Received"
    val vehicleReceived: String get() = "Vehicle Received"
    val cancelTransaction: String get() = "Cancel Transaction"
    val cancelTransactionConfirmation: String get() = "Submitting this will cancel the transaction."
    val reportIssues: String get() = "Report Issues"
    val removeIssueReport: String get() = "Clear"
    val updateIssueReport: String get() = "Update Issue Report"
    val submitIssueReport: String get() = "Submit Issue Report"
    val pending: String get() = "Pending"
    val pendingOffer: String get() = "Pending Offer"
    val pendingOffers: String get() = "Pending Offers"
    val placeABid: String get() = "Place a bid"
    val proxyBid: String get() = "Proxy Bid: "
    val proxyBids1: String get() = "Proxy Bids: "
    val recentlySold2: String get() = "Recently Sold"
    val reserve1: String get() = "Reserve: "
    val reserve2: String get() = "Reserve"
    val sell: String get() = "Sell"
    val seller: String get() = "Seller"
    val selling: String get() = "Selling"
    val starting: String get() = "Starting"
    val buying: String get() = "Buying"
    val completing: String get() = "Completing"
    val sellingCenter: String get() = "Selling Center"
    val sold: String get() = "Sold"
    val soldBy: String get() = "Sold by"
    val submitToAuction: String get() = "Submit to Auction"

    // Vehicle Issues
    val titleBranding: String get() = "Title Branding"
    val airConditioningIssue: String get() = "Air Conditioning Issue"
    val completelyClean: String get() = "Completely Clean"
    val announcements: String get() = "Announcements"
    val damage: String get() = "Damage"
    val damages: String get() = "Damages"
    val flood: String get() = "Flood"
    val floodDamage: String get() = "Flood Damage"
    val issues: String get() = "Issues"
    val lemonLaw: String get() = "Lemon Law"
    val nonRunner: String get() = "Non-Runner"
    val odometerIssue: String get() = "Odometer Issue"
    val paintWork: String get() = "Paint Work"
    val priorAccident: String get() = "Prior Accident"
    val rentalTaxi: String get() = "Rental / Taxi"
    val salvage: String get() = "Salvage"
    val title: String get() = "Title"
    val warningLights: String get() = "Warning Lights"
    val canadian: String get() = "Canadian"
    val titleNotPresent: String get() = "Title Not Present"
    val noItsPerfect: String get() = "No, it's perfect."
    val stolenRecovery: String get() = "Stolen / Recovery"
    val structuralDamage: String get() = "Structural Damage"
    val transmissionIssue: String get() = "Transmission Issue"
    val trueMileageUnknown: String get() = "True Mileage Unknown"
    val towRequired: String get() = "Tow Required"

    // Vehicle Basics

    val basicInformation: String get() = "Basic Information"
    val basics: String get() = "Basics"

    val make: String get() = "Make"
    val makes: String get() = "Makes"
    val model: String get() = "Model"
    val models: String get() = "Models"
    val options: String get() = "Options"
    val trim1: String get() = "Trim:"
    val trim2: String get() = "Trim"
    val year: String get() = "Year"

    val fuel1: String get() = "Fuel:"
    val fuel2: String get() = "Fuel"
    val fuelType: String get() = "Fuel Type"
    val gasoline: String get() = "Gasoline"
    val hybrid: String get() = "Hybrid"
    val diesel: String get() = "Diesel"
    val electric: String get() = "Electric"
    val thisIsAnElectricVehicle: String get() = "This is an electric vehicle."

    val transmission1: String get() = "Transmission"
    val transmission2: String get() = "Transmission:"
    val automatic: String get() = "Automatic"
    val manual: String get() = "Manual"
    val manualTransmission: String get() = "Manual Transmission"
    val thisVehicleHasAManualTransmission: String get() = "This vehicle has a manual transmission."

    val colors: String get() = "Colors"
    val exterior1: String get() = "Exterior"
    val exterior2: String get() = "Exterior:"
    val exteriorColor: String get() = "Exterior Color"
    val interior1: String get() = "Interior"
    val interior2: String get() = "Interior:"
    val interiorColor: String get() = "Interior Color"

    val holdSteadyToScanVIN: String get() = "Hold steady to scan VIN..."
    val keys1: String get() = "Keys"
    val keys2: String get() = "Keys:"
    val listAVehicle: String get() = "List a vehicle"
    val mileage: String get() = "Mileage"
    val odometer: String get() = "Odometer"
    val thisVehicleHasNoReportedDamage: String get() = "This vehicle has no reported damage."
    val condition: String get() = "Condition"
    val tireCondition1: String get() = "Tire Condition"
    val tireCondition2: String get() = "Tire Condition:"
    val reconditioning: String get() = "Has this vehicle been through reconditioning?"
    val reconditioning2: String get() = "Reconditioning:"
    fun reconditioningLabel(status: ReconditioningStatus?) = when (status) {
        ReconditioningStatus.NewTrade -> "New Trade"
        ReconditioningStatus.FrontlineUnit -> "Frontline Unit"
        ReconditioningStatus.CPO -> "CPO"
        else -> ""
    }
    val activeWarranty: String get() = "Active warranty?"
    val activeWarranty2: String get() = "Active warranty:"
    val vehicle: String get() = "Vehicle"
    val vehicleDetail: String get() = "Vehicle Detail"
    val vin: String get() = "VIN"
    val unknownVehicle: String get() = "Unknown vehicle"

    // Add Vehicle
    val add: String get() = "Add"
    val addAnother: String get() = "Add another"
    val failedToUpdateVehiclePleaseCheckYourInternet: String get() = "Failed to update vehicle. Please check your internet and try again, or report this issue if it is consistent."
    val failedToUploadPicture: String get() = "Failed to upload picture"
    val failedToUploadVehiclePleaseCheckYourInternet: String get() = "Failed to upload vehicle. Please check your internet and try again, or report this issue if it is consistent."
    val listThisVehicleInTheNextAuction: String get() = "List this vehicle in the next auction"
    val pleaseManuallyEnterInYourVIN: String get() = "Please manually enter in your VIN"
    val pleaseSelectAVehicleOnTheLeft: String get() = "Please select a vehicle on the left!"
    val pleaseTryThePictureTakingAgain: String get() = "Please try the picture taking again."
    val takeAPictureOfTheDashboardToShow: String get() = "Take a picture of the dashboard to show the odometer"
    val takeAPictureOfTheOpenDriversDoor: String get() = "Take a picture of the interior of the vehicle with the drivers door open.\nThe steering wheel and both front seats should be visible in the picture"
    val takeAPictureOfTheVINStickerOn: String get() = "Take a picture of the VIN sticker on the inside of the door"
    val takeAPictureOfYourVehicleAtA: String get() = "Take a picture of your vehicle at a 45 degree angle"
    val takeAPictureToEnterVINManually: String get() = "Take a picture to enter VIN manually"
    val takePicture: String get() = "Take Picture"
    val uploadingYourVehicle: String get() = "Uploading your vehicle..."
    val vINScannedTakePictureWhenReady: String get() = "VIN Scanned! Take picture when ready"
    val enableCameraAccessInDeviceSettings: String get() = "Enable camera access in device settings"
    val manualVINEntry: String get() = "Manual VIN Entry"
    val pricing: String get() = "Pricing"
    val attachments: String get() = "Attachments"
    val recommendedUploads: String get() = "If you have an inspection report, window sticker or any other PDF document providing additional details about the vehicle, please upload it here."
    val location: String get() = "Location"

    // Generic
    fun yesOrNo(yes: Boolean) = if (yes) "Yes" else "No"
    val admin: String get() = "Admin"
    val copyToClipboard: String get() = "Copy to clipboard"
    val copyVINToClipboard: String get() = "Copy VIN to clipboard"
    val error: String get() = "Error"
    val moreInfo: String get() = "More Info"
    val none: String get() = "None"
    val notes: String get() = "Notes"

    val close: String get() = "Close"
    val off: String get() = "Off"
    val ok: String get() = "Ok"
    val done: String get() = "Done"
    val submit: String get() = "Submit"

    val save: String get() = "Save"
    val saveChanges: String get() = "Save Changes"
    val saveAndAccept: String get() = "Save and Accept"
    val saved: String get() = "Saved"

    val settings: String get() = "Settings"
    val masquerade: String get() = "Masquerade"
    val endMasquerade: String get() = "End Masquerade"
    val test: String get() = "Test"

    val people: String get() = "People"
    val profile: String get() = "Profile"
    val edit: String get() = "Edit"
    val updateFailed1: String get() = "Update failed"
    val updateFailed2: String get() = "Update Failed"
    val updateListing: String get() = "Update Listing"
    val uploadError: String get() = "Upload Error"
    val uploadFailed: String get() = "Upload Failed"

    val yourTextHere: String get() = "Your text here"
    val addItem: String get() = "Add item"
    val clear: String get() = "Clear"
    val confirm: String get() = "Confirm"
    val confirmUpdate: String get() = "Confirm Update"
    val remove: String get() = "Remove"
    val removeEntry: String get() = "Remove Entry"
    val removeOption: String get() = "Remove option"
    val cancel: String get() = "Cancel"
    val create: String get() = "Create"
    val dashboard: String get() = "Dashboard"
    val description: String get() = "Description"
    val images: String get() = "Images"
    val seeMore: String get() = "See More"
    val select: String get() = "Select"
    val selectOne: String get() = "Select one"
    val send: String get() = "Send"
    val subscription: String get() = "Subscription"
    val support: String get() = "Support"
    val termsOfUse: String get() = "Terms Of Use"
    val privacyPolicy: String get() = "Privacy Policy"
    val time: String get() = "Time"
    val total: String get() = "Total"
    val uploadNew: String get() = "Upload New"
    val scheduleNewAuction: String get() = "Schedule New Auction"
    val scheduleAuction: String get() = "Schedule Auction"

    val configureScheduledAuction: String get() = "Configure Scheduled Auction"
    val configureAuction: String get() = "Configure auction"
    val auctionName: String get() = "Auction Name"
    val scheduledDateAndTime: String get() = "Scheduled Date and Time"
    val delete: String get() = "Delete"
    val failed: String get() = "Failed ."

    val city: String get() = "City"
    val city1: String get() = "City:"
    val state: String get() = "State"
    val state1: String get() = "State:"
    val streetAddress1: String get() = "Street Address:"
    val streetAddress: String get() = "Street Address (optional)"
    val zip: String get() = "Zip Code"
    val zip1: String get() = "Zip Code:"

    val allProxyBidsOnThisListingWillBe: String get() = "All Proxy Bids on this listing will be cancelled."
    val amount: String get() = "Amount:"
    val anUnknownErrorOccurredIsYourInternetConnection: String get() = "An unknown error occurred.  If this persists, please contact support@lussotechnologies.com."
    val auctions: String get() = "Auctions"
    val buyingCenter: String get() = "Buying Center"
    val design: String get() = "Design"
    val doesThisVehicleHaveAnyVisibleDamage: String get() = "Conduct a thorough inspection of the vehicle and photograph any imperfections. This includes dents, scratches, curb damage to wheels, interior tears, or illuminated warning lights."
    val establishQuoteAmount: String get() = "Establish Quote Amount"
    val explainTheSituationHere: String get() = "Explain the situation here"
    val explainWhatYouKnowAboutThisHere: String get() = "Explain what you know about this here..."
    val explanation: String get() = "Explanation"
    val titleExplanation: String get() = "Please specify the source bank of the title, the estimated timeframe for its receipt, and any other pertinent details regarding the title."
    val favorite: String get() = "Favorite"
    val inactive: String get() = "Inactive"
    val inventory: String get() = "Inventory"
    val lastWeek: String get() = "Last week"
    val lusso: String get() = "Lusso"
    val dealershipSellsMakes: String get() = "Brands Represented"
    val noAdditionalInformationGiven: String get() = "No additional information given"
    val noAdditionalInformationWasProvided: String get() = "No additional information was provided."
    val noMileageInformation: String get() = "No mileage information"
    val noVehiclesMatchedTheseConditions: String get() = "No vehicles matched these conditions."
    val noVehiclesMatchedTheseConditionsSave: String get() = "No vehicles matched these conditions. Save this search to receive notifications for new results."
    val optional: String get() = "Optional"
    val previousRuns: String get() = "Previous Ilusso Auction Results"
    val previously: String get() = "Previously:"
    val priorAccidentReported: String get() = "Prior Accident Reported"
    val proposal: String get() = "Proposal"
    val rerunInTheUpcomingWeek: String get() = "Rerun in the upcoming week"
    val resetData: String get() = "Reset data"
    val resetResult: String get() = "Reset result"
    val reviews: String get() = "Reviews"
    val samplePage: String get() = "Sample Page"
    val severity: String get() = "Severity"
    val simulator: String get() = "Simulator"
    val soFarThisAuction: String get() = "So far this auction"
    val theVehicleHasBeenReceived: String get() = "The vehicle has been received"
    val theVehiclesDescriptionWasNotAccurate: String get() = "The vehicle's description was not accurate"
    val thereWasAnErrorCreatingTheVehicle: String get() = "There was an error creating the vehicle."
    val thereWasAnErrorSavingYourChanges: String get() = "There was an error saving your changes."
    val thereWasAnErrorUpdatingThisProfile: String get() = "There was an error updating this profile."
    val theyMadePayingForAndReceivingTheVehicle: String get() = "The seller made paying for the vehicle difficult"
    val theyMadeReceivingPaymentDifficult: String get() = "They made receiving payment difficult"
    val reportWillCancelTransaction: String get() = "Submitting this issue report will cancel the transaction."
    val thisFieldIsRequiredPleaseProvideAResponse: String get() = "This field is required. Please provide a response."
    val thisIsAVehicleYouAreSelling: String get() = "This is a vehicle you are selling"
    val thisIsTheFirstTimeThisVehicleHas: String get() = "This is the first Ilusso Auction for this vehicle."
    val thisVehicleAppearsToMeetTheMinimumCriteria: String get() = "This vehicle appears to meet the minimum criteria to be"
    val unlist: String get() = "Unlist"
    val withinCustomRange: String get() = "Within custom range"
    val yesTakeTheFirstPicture: String get() = "Yes, take the first picture"
    val youAndOneOtherMarkedThisDealershipAs: String get() = "You and one other marked this dealership as preferred."
    val youHaveNoNotificationsYet: String get() = "You have no notifications yet."
    val yourInstructionalTextGoesHere: String get() = "Your instructional text goes here."
    val yourReserveIsPrivateToYouAndCan: String get() = "Your reserve price is confidential and can be adjusted at any time before the auction begins."
    val youveMarkedThisVehicleAsAFavorite: String get() = "You've marked this vehicle as a favorite."
    val externalInformation: String get() = "Links"

    val informationIsAccurate: String get() = "This information is accurate to the best of my knowledge"
    val doYouHaveYourTitle: String get() = "Do you have the title?"
    val titleNotPresentConfirm: String get() = "No, I do not have the title yet."
    val titlePresentConfirm: String get() = "Yes, the title is present."
    val titleNotPresentWarning: String get() = "Per the terms and conditions, if you sell a car on Brand Specific Auctions and don't currently possess the title, you are required to hand over the car to the buyer and will be funded upon obtaining the title."
    val vehicleDescriptionPrompt: String get() = "Provide a detailed vehicle description to offer buyers additional information. This section can include the MSRP, notable upgrades, aftermarket modifications, paint protection film (PPF), wrap, service history, or any other significant details."
    val noVisibleDamage: String get() = "This vehicle has no visible damage!"
    val noVehicleDescriptionProvided: String get() = "No description provided"

    val returnToList: String get() = "Return to List"
    val dealershipName: String get() = "Dealership Name"
    val introInstructions: String get() = "In order to bid at iLusso Auction these must be completed"
}


object Strings : StringsBase